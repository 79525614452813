import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import styled from 'styled-components';
import PageTitle from '../components/atoms/PageTitle';
import HeadBase from '../components/molecules/HeadBase';
import { CROCCHA_API, MetaDesc, MetaOGP } from '../utils';

const PrivacycPolicyPage = () => (
  <BaseLayoutTemplate>
    <HeadBase
      metas={{
        title: 'プライバシーポリシー | tryangle株式会社',
        description: MetaDesc,
        openGraph: {
          type: 'article',
          url: `${CROCCHA_API.SELF_DOMAIN}/privacypolicy/`,
          title: 'プライバシーポリシー | tryangle株式会社',
          description: MetaDesc,
          images: [
            {
              url: MetaOGP,
              width: 1200,
              height: 630,
              alt: 'tryangle株式会社',
            },
          ],
          site_name: 'tryangle株式会社',
        },
      }}
    />

    {/*  */}
    <PageTitle title='プライバシーポリシー' span='' />
    <Section>
      <p>
        tryangle株式会社（以下「当社」）が運営するモバイルサービス、ウェブサービス、及びcrocchaのサービスで、またはこれに関連して提供されるあらゆるソフトウェア(以下「本サービス」と総称)に関して情報を収集、利用、共有、および保護する方法と、利用者が自身の情報の収集および利用について選択できる内容について説明します。当社では以下のプライバシーポリシーに基づいて個人情報の適切な取り扱いに努めてまいります。
      </p>

      <h4>第1条 取得する情報及び取得方法</h4>

      <p>
        1.当社は、第2条に記載する利用目的の達成のために、ユーザーによるcrocchaの利用に関連する以下のユーザー情報を当社の技術を利用してユーザーから自動的に取得することができるものとします。
      </p>

      <p>
        (1)
        ユーザーがcrocchaにアクセスするために使用したコンピュータやスマートフォン等の機器から当社が取得できる端末の設定情報、端末固有のID等の端末情報（これらの情報にはユーザーのIPアドレス、ブラウザの種類（識別子を含みます。）、ハードウェアモデル、OSバージョン、クラッシュ情報、モバイルネットワーク情報、位置情報及びアクセスしたページに関する情報等が含まれる場合があります。）
      </p>
      <p>
        (2)
        ユーザーによるcrocchaの利用に関連して当社が取得できるログ情報（これらの情報には、IPアドレス、crocchaにアクセスする前にユーザーが訪問していたウェブサイトの情報、ブラウザの種類・設定やアクセスの日時又は履歴が含まれます。また、ログ情報の取得のために、crocchaを利用したユーザーのコンピュータ又はスマートフォンに対して、クッキーを送信してこれを記録させ、クッキー情報を収集する場合があります。また、クッキー情報にはウェブブラウザを閉じるまで残存するセッションクッキーとウェブブラウザを終了してもウェブブラウザ内部に保存されるパーシステントクッキーがあります。ウェブブラウザ終了時にパーシステントクッキーを削除したい場合は、ウェブブラウザの設定で終了時にクッキーを削除する必要があります。但し、これらのクッキー情報にはユーザー個人を特定し、識別する情報は含まれておりません。）
      </p>

      <p>
        2.当社は、第2条に規定する利用目的の達成のために、ユーザーによるcrocchaの利用に関連する以下のユーザー情報をユーザー自身の行為に基づき取得することができるものとします。
      </p>

      <p>
        (1)
        ユーザー登録のために当社に提供される、ユーザーの氏名またはニックネーム、ID、性別、生年月日、パスワード、メールアドレス、居住国・地域、プロフィール画像、プロフィール（自己紹介文）、家族・ペット、HP・ブログのURL、instagramアカウント、Facebookアカウント及びこれに関連する情報
      </p>
      <p>
        (2)
        ユーザーが、crocchaの投稿機能を用いて投稿又は他のユーザーと共有する際の投稿コンテンツ（画像、コメント、ユーザーが他のユーザーとの間でcroccha上においてメッセージを交換した場合のメッセージを含みます。）及びこれに関連する情報
      </p>

      <h4>第2条 利用目的</h4>
      <p>
        当社は、ユーザーから取得したユーザー情報を、以下の利用目的の達成に必要な範囲において利用することができるものとします。なお、以下の利用目的以外の目的で利用する場合は、本プライバシーポリシー第4条の定めに従い、個別に利用目的を通知し、かつユーザーの同意を取得するものとします。
      </p>

      <p>
        (1)
        crocchaの運用及び提供並びにcroccha上のレコメンド機能、カスタマイズ機能その他の機能に関連して独自化されたサービスの提供
      </p>
      <p>
        (2) crocchaの改善及び新機能の開発のための調査、分析及び統計資料の作成
      </p>
      <p>(3) crocchaに関する各種キャンペーン、懸賞企画及びアンケートの実施</p>
      <p>(4) ユーザーからの各種問い合わせ及びアフターサービスへの対応</p>
      <p>
        (5)
        crocchaの最新情報、ニュースレターの配信及びその他当社の提供する関連サービスに関する各種情報の配信
      </p>
      <p>(6) crocchaの運営上必要な事項の通知</p>
      <p>(7) 当社が実施するサービス又は企画に関する連絡</p>
      <p>(8) ユーザーの本人認証及び各種画面における登録情報の自動表示</p>
      <p>(9) 契約又は法令に基づく権利行使又は義務履行</p>

      <h4>第3条 同意</h4>
      <p>
        当社は、ユーザーが会員登録を行う際、利用規約（croccha利用規約）及び本プライバシーポリシーの記載内容につきユーザーの同意を取得するものとします。
      </p>

      <h4>第4条 変更</h4>
      <p>
        当社は、当社が取得するユーザー情報の利用目的及び、本プライバシーポリシーを変更する場合には、当社が妥当であると判断する方法で当該変更事項をユーザーに通知し、かつ当社所定の手続に従いユーザーの同意を取得するものとします。
      </p>

      <h4>第5条 ユーザー情報の委託</h4>
      <p>
        当社は、ユーザーに明示した利用目的の達成に必要な範囲で、ユーザー情報の管理及び保管に関する適切な契約を締結している第三者に対し、当社がユーザーから取得したユーザー情報を委託することができるものとします。
      </p>

      <h4>第6条 ユーザー情報の第三者提供</h4>

      <p>
        1.以下のいずれかに該当する場合を除き、当社は、ユーザーから取得したユーザー情報を第三者に提供又は開示しないものとします。
      </p>

      <p>(1) 法令に基づく場合</p>
      <p>(2) ユーザーの事前の同意を得ている場合</p>
      <p>
        (3)
        ユーザー及び第三者の生命、身体、財産等、公共の利益又はユーザーの利益のため必要であると当社が判断した場合
      </p>
      <p>
        (4)
        合併、分社化、事業譲渡等により事業が継承されユーザー情報を引き継ぐ場合
      </p>
      <p>(5) 第5条に基づき委託する場合</p>

      <p>
        2.当社は、複数のユーザー情報を集計し、個人の特定が不可能な形でマーケティング及びプロモーションのために自ら又は第三者と共同で利用する場合があります。
      </p>

      <p>
        第7条
        個人情報を提供することの任意性及び当該情報を提供しなかった場合に生じる結果
      </p>

      <p>
        当社が取得するユーザー情報は、全てユーザーの意思によってご提供いただくものです。なお、crocchaの利用に関連して当社が取得する情報の全部又は一部をご提供いただけない場合は、crocchaの利用をお断りする場合がございますので、あらかじめご了承下さい。
      </p>

      <h4>第8条 情報の公開</h4>
      <p>
        1.ユーザーのユーザー名、croccha
        IDは、crocchaを提供するウェブサイト上に表示され全てのユーザーに対して公開されることになります。また、投稿コンテンツは、公開設定を行うことにより同じく公開されることになります。
      </p>
      <p>
        2.ユーザーがcrocchaを通じて他のユーザーとのメッセージ、その他のコニュニケーションを行った場合又は投稿コンテンツ若しくはこれに関する情報を共有する機能を利用した場合には、当該メッセージ又はコンテンツに含まれるテキスト、写真、動画、音声、URLのリンクその他の情報が他のユーザーに通知又は公開される場合があります。
      </p>
      <p>
        3.ユーザーは、crocchaの機能を利用して、第三者サイトとcrocchaとをリンクさせた場合、第三者サイトにおいてcroccha上で公開されている情報全部又は一部が公開されることになります。
      </p>
      <p>
        4.当社は、crocchaの利用を通じた情報の公開に起因してユーザーに何らかの損害又は損失が発生したとしても、当該損害又は損失に対し一切の責任を負いません。
      </p>

      <h4>第9条 13歳未満のユーザー情報の取得</h4>
      <p>
        1.crocchaの利用可能対象者には13歳未満の者を含みません。当社は、13歳未満のユーザー情報を収集せず、また登録を許可致しません。
      </p>
      <p>
        2.当社が13歳未満からユーザー情報を取得したと合理的に判断した場合は、当社は、自らの裁量で当該情報を削除することができるものとします。万が一そのような疑いがあると考えられる場合は、本プライバシーポリシー第12条記載の連絡先までご一報下さい。なお、当該削除に起因してユーザーに何らかの損害又は損失が発生したとしても、当社は、当該損害又は損失に対し一切の責任を負いません。
      </p>

      <h4>第10条 ユーザー情報の開示手続</h4>
      <p>
        1.当社は、ユーザー本人からの開示対象個人情報の開示、利用目的の通知、開示対象個人情報の内容が事実に反する場合等における訂正等、利用停止等及び第三者提供の停止（以下「開示等」といいます。）のご請求を受付いたします。
      </p>
      <p>(1) 開示等の求めのお申し出先</p>
      <p>(2) ご提出いただくもの</p>
      <ul>
        <li>①個人情報利用目的の通知・開示請求書</li>
        <li>②個人情報訂正等請求書</li>
        <li>③個人情報利用停止等請求書</li>
        <li>④本人確認のための書類（運転免許証、パスポートなど）</li>
        <li>
          ⑤法定代理人の場合は、上記④に加え、法定代理権があることを確認する書類
        </li>
      </ul>

      <p>(3)手数料</p>
      <p>
        当該ご請求のうち、開示のご請求及び利用目的の通知のご請求につきましては、当社所定の手数料をご負担いただきますので、あらかじめご了承ください。その他開示等のご請求の具体的な手続きにつきましては、第12条に定める問い合わせ窓口までお問い合わせください。
      </p>

      <h4>第11条 サービスの終了と情報の取扱い</h4>
      <p>
        1.ユーザーがcrocchaから退会する手続を完了した場合、又は当社がユーザーをcrocchaから退会させた場合、当社は、croccha上におけるユーザー情報の公開を速やかに停止致します。但し、当該停止時点で既に他のユーザーとの間でやり取りされたメッセージ又は共有機能を通して提供されている情報については、公開停止処理後であっても一部公開状態が継続される可能性があります。
      </p>
      <p>
        2.前項の公開停止後とも、当社は、関連する適用法令に従い、ユーザー情報のアーカイブを正当な事業上の目的のため必要最低限の範囲内において、当社の技術上合理的なセキュリティが確保された保管方法にて継続して保有することができるものとします。
      </p>

      <h4>第12条 問い合わせ窓口</h4>
      <p>
        当社は、crocchaにおけるユーザー情報の取扱いに関するお問い合わせを以下の窓口でお受け致します。
      </p>

      <p>crocchaカスタマーサポートセンター</p>
      <p>メールアドレス　：　support&#64;croccha.jp</p>
      <p>
        ※当社のサポートサービスの品質の向上及び正確な情報収集のため、当社は、ユーザーとのやり取り内容を記録及び保存することができるものとします。
      </p>
      <p>個人情報保護責任者　tryangle株式会社　代表取締役　藤原真吾</p>
    </Section>
  </BaseLayoutTemplate>
);

export default PrivacycPolicyPage;

const Section = styled.section`
  max-width: 800px;
  padding: 3rem 1rem;
  font-size: 14px;
  line-height: 1.6;
  > h4 {
    margin: 30px 0 8px;
    font-size: 1rem;
  }
`;
